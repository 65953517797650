import axios from 'axios'

const instance = axios.create({
	baseURL: 'https://intellivix.com:46438',
	//baseURL: 'http://localhost:8080/',
	timeout: 5000
})

instance.interceptors.request.use(
	function (config) {

		const isLogin = config.data ? config.data.isLogin : false
		const auth = sessionStorage.getItem('auth')
		//config.headers.common.authorization = isLogin && `Bearer ${auth}`
		// 요청 바로 직전
		// axios 설정값에 대해 작성합니다.
		return config
	},
	function (error) {
		// 요청 에러 처리를 작성합니다.
		return Promise.reject(error)
	}
)

instance.interceptors.response.use(
	function (response) {
		return response
	},

	function (error) {
		return Promise.reject(error)
	}
)

export default instance
